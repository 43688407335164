<template>
  <footer class="footer">
    <b-container>
      <b-row>
        <b-col lg="3" md="3">
          <section class="footer__main-routes">
            <b-nav>
              <b-nav-item
                v-for="(nav, index) in navLinks"
                :key="index"
                :href="nav.link"
                class="mx-2"
                target="_blank"
              >
                <b-img :src="nav.img" height="35px" />
              </b-nav-item>
            </b-nav>
          </section>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <p class="font-medium">
            <b-img
              :src="require('@/assets/imgs/footer/6.png')"
              class="footer-icon"
              height="35px"
            />
            <span v-html="contact_phone || ''"></span>
          </p>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <p class="font-medium d-flex">
            <b-img
              :src="require('@/assets/imgs/footer/7.png')"
              class="footer-icon"
              height="35px"
            />
            <span class="line-clamp" v-html="address || ''"></span>
          </p>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <p class="font-medium">
            <b-img
              :src="require('@/assets/imgs/footer/8.png')"
              class="footer-icon"
              height="35px"
            />
            <span v-html="email || ''"></span>
          </p>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col sm="12" class="text-sm-center">
          <p class="footer__copyright my-4">
            {{ getCurrentYear }} {{ $t("hint.copyrights") }}
          </p>
        </b-col>
      </b-row> -->
    </b-container>
  </footer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Footer",

  data() {
    return {
      navLinks: [
        {
          name: "snapchat",
          link: "",
          img: require("@/assets/imgs/footer/2.png")
        },
        {
          name: "features",
          link: "",
          img: require("@/assets/imgs/footer/3.png")
        },
        { name: "about", link: "", img: require("@/assets/imgs/footer/4.png") },
        {
          name: "conditions",
          link: "",
          img: require("@/assets/imgs/footer/5.png")
        }
      ],
      contact_phone: "",
      address: "",
      email: ""
    };
  },
  computed: {
    getCurrentYear() {
      return new Date().getFullYear();
    },
    ...mapGetters(["GlobalSettings", "settings"])
  },
  watch: {
    GlobalSettings: {
      handler() {
        this.navLinks[0].link = this.settings("snapchat");
        this.navLinks[1].link = this.settings("instagram");
        this.navLinks[2].link = this.settings("facebook");
        this.navLinks[3].link = this.settings("twitter");
        this.contact_phone = this.settings("contact_phone");
        this.address = this.settings("address");
        this.email = this.settings("contact_email");
      },
      immediate: true
    }
  }
};
</script>
